@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@200;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
@font-face {
  font-family: "myriad";
  src: url("/static/media/Myriad Pro Light.b0bb7658.otf");
}
body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  /* font-family: 'myriad', sans-serif; */
  /* font-family: 'Source Sans Pro', sans-serif; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  background: linear-gradient(90deg, hsla(170, 100%, 99%, 1) 0%, hsla(173, 68%, 47%, 0.1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body > *{
  font-family: 'myriad', sans-serif!important;

}

:root{
 /* --primary: rgb(38, 200, 182) */
 --primary: rgb(36, 199, 183)
}

.about-btn1 a {
  width: 40%;
  text-align: center;
}

.about-btn1 a {
  padding: 15px 60px;
}

a {
  text-decoration: none;
}

.sticky {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 1;
}
html,
body * {
  /* font-family: 'Source Sans Pro', sans-serif!important; */
  /* font-family: "myriad", sans-serif !important; */
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
.dropdown {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  z-index: 9999;
}

.dropdown li {
  position: relative;
  padding: 8px 16px;
  color: black;
  /* font-size: 14px; */
  font-size: 16px;
}
.dropdown li:hover {
  cursor: pointer;
  background: var(--primary);
  transition: background 0.3s ease-in-out;
}

.dropdown li:hover a {
  color: white;
}

.dropdown a:hover li {
  color: white;
}

.dropdown li a {
  display: block;
  /* padding: 0 10px; */
  /* height: 50px;
    line-height: 50px; */
  color: #000;
  cursor: pointer;
  text-decoration: none;
  /* font-weight: 600; */
  /* font-size: 14px; */
  font-size: 16px;
}

.dropdown li ul {
  position: absolute;
  margin: 0;
  padding: 0;
  /* list-style: none; */
  background-color: #fff;
  width: 180px;
  left: 0;
  top: 130%;
  max-height: 0;
  transition: max-height 0.5s;
  overflow: hidden;
  /* border-radius: 8px; */
}

.dropdown li ul li a {
  height: 55px;
  line-height: 35px;
  color: #999;
  display: flex;
  align-items: center;
}

.dropdown li:hover > ul {
  max-height: 400px;
}

#our-partners,
#our-categories,
#contact-us,
#about-section,
#our-references {
  scroll-margin-top: 200px;
  scroll-margin-bottom: 200px;
}

.app-form input {
  width: 100%;
}

.dropdown li:hover .wide-dropdown {
  /* border-top: 1px solid lightgray; */
  transform: scaleY(1);
  opacity: 1;
  z-index: 100;
  display: block;
}
/* .dropdown li:hover .wide-dropdown {
  max-height: 400px;
}

.wide-dropdown {
  position: absolute;
  top: 70%;
  left: 0;
  width: 100%;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s  ease-in-out;
  overflow: hidden;
  z-index: 9999;
  padding: 16px;

} */

.wide-dropdown {
  top: 70%;
  left: 0;
  width: 100%;
  padding: 24px;
  min-height: 200px;
  background-color: #ffffff;
  transition: all 0.4s ease;
  transform: scaleY(0);
  transform-origin: 0 0 0;
  opacity: 0;
  position: absolute;
  left: 0;
  right: auto;
  box-shadow: 0 2px 3px rgba(40, 40, 40, 0.16);
  border-radius: 0;
  display: block;
}

#header {
  position: relative;
}
.underline-on-hover {
  position: relative;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.underline-on-hover::after {
  content: "";
  position: absolute;
  left: 20px;
  bottom: -2px;
  width: 100%;
  height: 1px;
  background-color: #000; /* Change to your desired underline color */
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.underline-on-hover:hover::after {
  transform: scaleX(1);
}

@font-face {
    font-family: "flaticon";
    src: url(/static/media/flaticon.55653367.ttf) format("truetype"),
url(/static/media/flaticon.244e5df0.woff) format("woff"),
url(/static/media/flaticon.7d4ae293.woff2) format("woff2"),
url(/static/media/flaticon.ff50d96d.eot#iefix) format("embedded-opentype"),
url(/static/media/flaticon.848eb55d.svg#flaticon) format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-sort-by-attributes:before {
    content: "\f101";
}
.flaticon-sort-by-attributes-interface-button-option:before {
    content: "\f102";
}

.title-bg{
    position: absolute;
    z-index: -1;
}

.rotate-scale-up {
    animation: rotate-scale-up 5s linear infinite both;

}

/* ----------------------------------------------
 * Generated by Animista on 2021-12-2 21:17:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-scale-up
 * ----------------------------------------
 */

@keyframes rotate-scale-up {
    0% {
        transform: scale(1) rotateZ(0);
    }
    50% {
        transform: scale(1.5) rotateZ(180deg);
    }
    100% {
        transform: scale(1) rotateZ(360deg);
    }
}

