@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@font-face {
  font-family: "myriad";
  src: url("assets/fonts/Myriad\ Pro\ Light.otf");
}
body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  /* font-family: 'myriad', sans-serif; */
  /* font-family: 'Source Sans Pro', sans-serif; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  background: linear-gradient(90deg, hsla(170, 100%, 99%, 1) 0%, hsla(173, 68%, 47%, 0.1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body > *{
  font-family: 'myriad', sans-serif!important;

}

:root{
 /* --primary: rgb(38, 200, 182) */
 --primary: rgb(36, 199, 183)
}
