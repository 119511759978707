.title-bg{
    position: absolute;
    z-index: -1;
}

.rotate-scale-up {
    -webkit-animation: rotate-scale-up 5s linear infinite both;
    animation: rotate-scale-up 5s linear infinite both;

}

/* ----------------------------------------------
 * Generated by Animista on 2021-12-2 21:17:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-scale-up
 * ----------------------------------------
 */

@keyframes rotate-scale-up {
    0% {
        -webkit-transform: scale(1) rotateZ(0);
        transform: scale(1) rotateZ(0);
    }
    50% {
        -webkit-transform: scale(1.5) rotateZ(180deg);
        transform: scale(1.5) rotateZ(180deg);
    }
    100% {
        -webkit-transform: scale(1) rotateZ(360deg);
        transform: scale(1) rotateZ(360deg);
    }
}
