@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?edb131e9c342f2581d9374ea6f784fb3") format("truetype"),
url("./flaticon.woff?edb131e9c342f2581d9374ea6f784fb3") format("woff"),
url("./flaticon.woff2?edb131e9c342f2581d9374ea6f784fb3") format("woff2"),
url("./flaticon.eot?edb131e9c342f2581d9374ea6f784fb3#iefix") format("embedded-opentype"),
url("./flaticon.svg?edb131e9c342f2581d9374ea6f784fb3#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-sort-by-attributes:before {
    content: "\f101";
}
.flaticon-sort-by-attributes-interface-button-option:before {
    content: "\f102";
}
