.about-btn1 a {
  width: 40%;
  text-align: center;
}

.about-btn1 a {
  padding: 15px 60px;
}

a {
  text-decoration: none;
}

.sticky {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 1;
}
html,
body * {
  /* font-family: 'Source Sans Pro', sans-serif!important; */
  /* font-family: "myriad", sans-serif !important; */
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
.dropdown {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  z-index: 9999;
}

.dropdown li {
  position: relative;
  padding: 8px 16px;
  color: black;
  /* font-size: 14px; */
  font-size: 16px;
}
.dropdown li:hover {
  cursor: pointer;
  background: var(--primary);
  transition: background 0.3s ease-in-out;
}

.dropdown li:hover a {
  color: white;
}

.dropdown a:hover li {
  color: white;
}

.dropdown li a {
  display: block;
  /* padding: 0 10px; */
  /* height: 50px;
    line-height: 50px; */
  color: #000;
  cursor: pointer;
  text-decoration: none;
  /* font-weight: 600; */
  /* font-size: 14px; */
  font-size: 16px;
}

.dropdown li ul {
  position: absolute;
  margin: 0;
  padding: 0;
  /* list-style: none; */
  background-color: #fff;
  width: 180px;
  left: 0;
  top: 130%;
  max-height: 0;
  -webkit-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;
  overflow: hidden;
  /* border-radius: 8px; */
}

.dropdown li ul li a {
  height: 55px;
  line-height: 35px;
  color: #999;
  display: flex;
  align-items: center;
}

.dropdown li:hover > ul {
  max-height: 400px;
}

#our-partners,
#our-categories,
#contact-us,
#about-section,
#our-references {
  scroll-margin-top: 200px;
  scroll-margin-bottom: 200px;
}

.app-form input {
  width: 100%;
}

.dropdown li:hover .wide-dropdown {
  /* border-top: 1px solid lightgray; */
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  z-index: 100;
  display: block;
}
/* .dropdown li:hover .wide-dropdown {
  max-height: 400px;
}

.wide-dropdown {
  position: absolute;
  top: 70%;
  left: 0;
  width: 100%;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s  ease-in-out;
  overflow: hidden;
  z-index: 9999;
  padding: 16px;

} */

.wide-dropdown {
  top: 70%;
  left: 0;
  width: 100%;
  padding: 24px;
  min-height: 200px;
  background-color: #ffffff;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -o-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0 0;
  -ms-transform-origin: 0 0 0;
  -o-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  opacity: 0;
  position: absolute;
  left: 0;
  right: auto;
  -moz-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.16);
  -webkit-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.16);
  box-shadow: 0 2px 3px rgba(40, 40, 40, 0.16);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  display: block;
}

#header {
  position: relative;
}
.underline-on-hover {
  position: relative;
  display: inline-block;
  width: fit-content;
}

.underline-on-hover::after {
  content: "";
  position: absolute;
  left: 20px;
  bottom: -2px;
  width: 100%;
  height: 1px;
  background-color: #000; /* Change to your desired underline color */
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.underline-on-hover:hover::after {
  transform: scaleX(1);
}
